import React from "react";
import {useQuery} from '../../utils/query';
import ProfileButton from '../../components/profile-button';
import GetAccessButton from '../../components/get-access-button';
import GetAllMessagesButton from '../../components/get-all-messages-button';

function Callback() {
    const query = useQuery();
    const code = query.get('code');
    const state = query.get('state');

    return (
        <div>
            <div>code <span>{code}</span> state <span>{state}</span></div>
            <GetAccessButton code={code}/>
            <ProfileButton/>
            <GetAllMessagesButton/>
        </div>
    )
}

export default Callback;
