import { ACCESS_TOKEN } from '../constant';

const getToken = () => {
    return localStorage.getItem(ACCESS_TOKEN);
}

const setToken = (token) => {
    localStorage.setItem(ACCESS_TOKEN, token);
}
const deleteToken = () => {
    localStorage.removeItem(ACCESS_TOKEN);
}

export {getToken, setToken, deleteToken}
