import React from "react";
import {getAccessToken, getProfile, linkedinApi} from "../../api";
import {BE_URL, clientId, clientSecret, redirectUri} from "../../config";
import {deleteToken, getToken, setToken} from "../../utils/token";
import {addGetParams} from "../../utils/query";

function GetAccessButton({code}) {
    const getAccessTokenData = () => {
        const url = `${BE_URL}/accessToken`,
            params = {
                grant_type: 'authorization_code',
                code,
                redirect_uri: `${redirectUri}callback`,
                client_id: clientId,
                client_secret: clientSecret
            }
        deleteToken();

        getAccessToken(addGetParams(url, params))
            .then((data) => {
                const {access_token, expires_in} = data;
                setToken(access_token)
            })
            .catch(() => {

            })
    }
    return (
        <button onClick={getAccessTokenData}>get accessToken</button>
    )
}

export default GetAccessButton;
