import axios from 'axios';
import {linkedinOauthUrl} from "../config";
import {addGetParams} from "../utils/query";
import {getToken} from "../utils/token";

axios.interceptors.request.use(function (config) {
    // Do something before request is sent

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

const linkedinApi = axios.create({
    baseURL: `https://api.linkedin.com/v2/`
});
const linkedinAuthApi = axios.create({
    baseURL: linkedinOauthUrl
});

const getAccessToken = (url) => {
    return new Promise((res, rej) => {
        axios.get(url)
            .then(function (response) {
                console.log(response);
                res(response.data);
            })
            .catch(function (error) {
                console.log(error);
                rej(error);
            })
    })
}

const getProfile = (url) => {
    return new Promise((res, rej) => {
        axios.get(url)
            .then(function (response) {
                console.log(response);
                res(response.data);
            })
            .catch(function (error) {
                console.log(error);
                rej(error);
            })
    })
}

const getAllMessages = (url) => {
    return new Promise((res, rej) => {
        axios.get(url)
            .then(function (response) {
                console.log(response);
                res(response.data);
            })
            .catch(function (error) {
                console.log(error);
                rej(error);
            })
    })
}

export { linkedinApi, getProfile, linkedinAuthApi, getAllMessages, getAccessToken };
