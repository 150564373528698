import React from 'react';
import {clientId, redirectUri, scope, linkedinOauthUrl, responseType} from '../../config';

function ConnectLinkedin() {
    const getToken = () => {
        debugger
        const url = `${linkedinOauthUrl}authorization?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}callback&state=fooobar&scope=${scope}`
        console.log(url)
        window.open(url);
    }

    return (
        <button onClick={getToken}>Get token</button>
    )
}

export default ConnectLinkedin;
