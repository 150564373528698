import {
    useLocation
} from "react-router-dom";

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const  addGetParams = (url, params) => {
    const urlObj = new URL(url);

     Object.keys(params).forEach(key => urlObj.searchParams.append(key, params[key]));

    return urlObj;
}
