import React from "react";
import {getProfile} from "../../api";
import {BE_URL} from "../../config";
import {getToken} from "../../utils/token";
import {addGetParams} from "../../utils/query";

function ProfileButton() {
    const getData = () => {
        const url = `${BE_URL}/me`,
            params = {
                access_token: getToken(),
            }

        getProfile(addGetParams(url, params))
            .then((data) => {
                console.log(data)
            })
            .catch(() => {

            })
    }

    return (
        <button onClick={getData}>get profile data</button>
    )
}

export default ProfileButton;
