import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/app';
import Callback from './containers/callback';
import Menu from './components/menu';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
      <Router>
          <div>
              <Menu />
              <Switch>
                  <Route exact path="/">
                      <App />
                  </Route>
                  <Route path="/callback">
                      <Callback />
                  </Route>
              </Switch>
          </div>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
