import React from 'react';
import './App.css';
import ConnectLinkedin from '../../components/connect-linkedin'

function Index() {

  return (
    <div className="App">
        <ConnectLinkedin></ConnectLinkedin>
    </div>
  );
}

export default Index;
